body{
    /*padding-top: 20px;*/
}

@media (max-width: 991px) {
    body {
      padding-top: 230px;
    }
  }

  @media only screen and (max-width: 990px) {
    .navcomponent{
      display: none;
    }
}